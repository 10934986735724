import React from 'react';

export const Button = ({ variant, size, className, children, ...props }) => {
  let baseClasses = 'px-4 py-2 rounded font-semibold';
  
  if (variant === 'outline') {
    baseClasses += ' border border-current';
  }
  
  if (size === 'icon') {
    baseClasses = 'p-2 rounded-full';
  }

  return (
    <button className={`${baseClasses} ${className}`} {...props}>
      {children}
    </button>
  );
};