import React from 'react';

export const Card = ({ className, children }) => (
  <div className={`rounded-lg shadow-md ${className}`}>{children}</div>
);

export const CardHeader = ({ children }) => (
  <div className="p-4 border-b">{children}</div>
);

export const CardTitle = ({ className, children }) => (
  <h2 className={`text-2xl font-bold ${className}`}>{children}</h2>
);

export const CardContent = ({ children }) => (
  <div className="p-4">{children}</div>
);