// components/ui/AvatarComponent.js
import React from 'react';
import * as Avatar from '@radix-ui/react-avatar';

const AvatarComponent = ({ src, alt, fallback, size = 'w-16 h-16' }) => (
  <Avatar.Root className={`inline-flex items-center justify-center overflow-hidden rounded-full bg-gray-200 ${size} border-2 border-blue-500`}>
    <Avatar.Image className="w-full h-full object-cover" src={src} alt={alt} />
    <Avatar.Fallback className="text-gray-500 text-sm leading-none" delayMs={600}>
      {fallback}
    </Avatar.Fallback>
  </Avatar.Root>
);

export default AvatarComponent;
