
import React, { useState, useEffect } from 'react';
import { ArrowUp, Menu, X, ChevronUp, ChevronDown } from 'lucide-react';
import { Card, CardContent, CardHeader, CardTitle } from './components/Card';
import { Button } from './components/Button';
import AvatarComponent from './components/ui/AvatarComponent';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './components/ui/AccordionComponent';


const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeSection, setActiveSection] = useState('');

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  const scrollToSection = (section) => {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const sections = document.querySelectorAll('section');
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      let currentSection = '';
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          currentSection = section.getAttribute('id');
        }
      });

      setActiveSection(currentSection);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = ['Home', 'About', 'Team', 'Focus', 'Research', 'Partner', 'Resources', 'Events', 'Contact'];

  const publications = [
    {
      author: "David Ojakaa",
      leadAuthor: [
        "Ambalu R, Rashid S, Atwa S, Otira M, Ndolo L, Ojakaa D. Factors related to women's use of health insurance cover in Navakholo, Kakamega County, Kenya: sub-county level results based on community household register. BMC Public Health. 2023 Mar 28;23(1):576.",
        "Ambalu R, Atwa S, Otira M, Ndolo L, Ojakaa D, Macharia J. Perceptions and experiences on community work improvement teams: the case of community health units in Kasarani sub-county, Nairobi, Kenya. doi:10.21203/rs.3.rs-2190909/v1. PPR:PPR569128.https://europepmc.org/article/ppr/ppr569128",
        "Kimathi G, Kiarie J, Njarambah L, Onditi J, Ojakaa D. A cross-sectional study of antimicrobial use among self-medicating COVID-19 cases in Nyeri County, Kenya. Antimicrob Resist Infect Control. 2022 Aug 30;11(1):111. doi: 10.1186/s13756-022-01150-7. PMID: 36042483; PMCID: PMC9427085",
        "Ojakaa, D. Feb. 2022: “Trends and Patterns in Fertility and its Proximate Determinants in sub-Saharan Africa, 1986-2018”. Chapter contribution to forthcoming book by Prof. Clifford Odimegwu, PhD, Wits University, South Africa . Routledge Handbook of African Demography.  https://www.routledge.com/The-Routledge-Handbook-of-African-Demography/Odimegwu-Adewoyin/p/book/9780367253288",
        "Ojakaa, D. et al. 2014.  “Acceptance of a Malaria vaccine by caregivers of sick children in Kenya.  Malaria Journal.13:172 ",
        "Ojakaa, D. et al. “Factors Related to the Motivation and Retention of Primary Health Care Workers in Three Disparate Regions in Kenya”. Human Resource for Health Journal. 2014, 12:33", 
        "Ojakaa, D. et al. (2011). “Perception on Malaria and Vaccines in Kenya”. Commentary in Human Vaccines. Kenya",
        "Ojakaa, D. et al. (May 30 2011) “Community Perceptions towards Malaria and Vaccines in Kenya”. Malaria Journal. Kenya.", 
        "Ojakaa, D. (2008). “Trends and Determinants of Unmet Need for Family Planning in Kenya”. Demographic and Health Research. DHS Working Paper No. 56. Macro International Inc. USAID MEASURE DHS Project. Kenya",
        "Ojakaa, D. et al. (May 2011) “Making Aid Effective at the Community Level – the AMREF Experience”. Kenya. Development in Practice.",
        "Ojakaa, D. (2008). The Fertility Transition in Kenya: Patterns and Determinants. Ph. D. Thesis. Département de Démographie, Faculté des Arts et des Sciences, Université de Montréal, Québec, Canada." ,        
        "Ojakaa, D. (1997) “Barriers to Behaviour Change: Result of Focus Group Discussion Conducted in a High HIV/AIDS Incidence Area of Kenya”. in UMEH, C.D. Ed.: Confronting the AIDS Epidemic. Cross-Cultural Perspectives on HIV/AIDS Education. Africa World Press. Trenton. NJ."
  
        
        
        
        // ... (other lead author publications)
      ],
      contributed: [
        "Sarah Karanja, Richard Gichuki, Patrick Igunza, Samuel Muhula, Peter Ofware, Josephine Lesiamon, Lepantas Leshore, Lenny Bazira Kyomuhangi-Igbodipe, Josephat Nyagero, Nancy Binkin & David Ojakaa (2018). 'Factors influencing deliveries at health facilities in a rural Maasai Community in Magadi sub-County, Kenya'. BMC Pregnancy and Childbirth volume 18, Article number: 5 (2018)",
        "Aliza, Monroe-Wise, Elizabeth Reisner, Kenneth Sherr1, David Ojakaa, Lilian Mbau, Paul Kisia, Samuel Muhula, and Carey Farquhar (2017). Using lean manufacturing principles to evaluate wait times for HIV-positive patients in an urban clinic in Kenya. International Journal of STD & AIDS 0(0) 1–9.",
        "van der Kop ML, Ojakaa DI, Patel A, et al (2013). “The effect of weekly short message service communication on patient retention in care in the first year after HIV diagnosis: study protocol for a randomised controlled trial (WelTel Retain)”. BMJ Open 2013;3:e003155. doi:10.1136/bmjopen-2013- 003155. Available: https://www.researchgate.net/publication/241694766_The_effect_of_weekly_short_message_service_communication_on_patient_retention_in_care_in_the_first_year_after_HIV_diagnosis_Study_protocol_for_a_randomised_controlled_trial_WelTel_Retain ",         "Caroline Kingori, Michael Reece, Samuel Obeng, Maresa Murray, Enbal Shacham, Brian Dodge, Emannuel Akach, Peter Ngatia, and David Ojakaa. “Impact of Internalized Stigma on HIV Prevention Behaviors Among HIV-Infected Individuals Seeking HIV Care in Kenya”. AIDS PATIENT CARE and STDs Volume 26, Number 12, 2012ª Mary Ann Liebert, Inc. DOI: 10.1089/apc.2012.0258",
        "Caroline Kingori, Michael Reece, Samuel Obeng, Maresa Murray, Enbal Shacham, Brian Dodge, Emannuel Akach, Peter Ngatia , David Ojakaa,: “Psychometric Evaluation of a Cross-Culturally Adapted Felt Stigma Questionnaire Among People Living with HIV in Kenya”. AIDS Patient Care and STDs Volume 27, Number 8, 2013 ª Mary Ann Liebert, Inc. DOI: 10.1089/apc.2012.0403. https://www.researchgate.net/publication/256083355_Psychometric_Evaluation_of_a_Cross-Culturally_Adapted_Felt_Stigma_Questionnaire_Among_People_Living_with_HIV_in_Kenya ",
        "Kirsten Smillie, Natasha Van Borek, Mia L van der Kop, Abigael Lukhwaro, Neville Li, Sarah Karanja, Anik R Patel, David Ojakaa and Richard T Lester (2014) Mobile health for early retention in HIV care: a qualitative study inbKenya (WelTel Retain), African Journal of AIDS Research, 13:4, 331-338, DOI: 10.2989/16085906.2014.961939 http://dx.doi.org/10.2989/16085906.2014.961939",
        "Mia L van der Kop, Samuel Muhula, Anna M Ekström, Kate Jongbloed, Kirsten Smillie, Bonface Abunah, Koki Kinagwi, Lennie B Kyomuhangi, Lawrence Gelmon, David I Ojakaa, Richard T Lester, Patricia O Awiti (2016). “Impact of Internalized Stigma on HIV Prevention Behaviors Among HIV-Infected Individuals Seeking HIV Care in Kenya”. Volume: 23 issue: 2, page(s): 314-320. DOI: https://doi.org/10.1177/1357633X16643457"



        
        // ... (other contributed publications)
      ]
    },
    {
      author: "Zacharie Tsala Dimbuene",
      peerReviewed: [
        "Amugsi, D. & Tsala Dimbuene, Zacharie. (2022). 'Basic determinants of child linear growth outcomes in sub-Saharan Africa: a cross-sectional survey analysis of positive deviants in poor households'. Scientific Reports.",
        "Dieng, C., Mberu, B., Tsala Dimbuene, Z., Faye, C., Amugsi, D. & Aboderin, I. (2021). 'Biomedical waste management in Dakar, Senegal: legal framework, health and environment issues; policy and program options', Cities&Health, 6(1), 208—222.",
        "Tsala Dimbuene, Zacharie & Turcotte, M. (2020). “Use of e-money transfer methods: lessons from the study on international money transfers from Canada”. Insights on Canadian Society. Catalogue no. 75-006-X. ISSN 2291—0840. https://publications.gc.ca/collections/collection_2020/statcan/75-006-x/75-006-2020-6-eng.pdf",
        "Tsala Dimbuene, Zacharie & Turcotte, M. (2019). “Study on International Money Transfers from Canada”. Ethnicity, Language and Immigration Thematic Series. Catalogue no. 89-657-X2019007. ISBN 978-0-660-30239-3. https://www150.statcan.gc.ca/n1/pub/89-657-x/89-657-x2019007- eng.pdf",
        "Amugsi, Dickson A., Tsala Dimbuene, Zacharie, Kimani-Murage, Elizabeth W. (2020). “Socio- demographic factors associated with normal linear growth among pre-school children living in better-off households: A multi-country analysis of nationally representative data”. https://doi.org/10.1371/journal.pone.0224118",
        "Amugsi, Dickson A., Tsala Dimbuene, Zacharie, Kimani-Murage, Elizabeth W. (Under review in BMJ Open). “Correlates of better linear growth among children in sub-Saharan Africa: Cross sectional survey analysis of positive deviants in poor households”. https://doi.org/10.1101/19006759",
        "Amugsi, Dickson Abanimi, Tsala Dimbuene, Zacharie, & Kyobutungi, Catherine (2019). “Correlates of the double burden of malnutrition among women: an analysis of cross sectional survey data from sub-Saharan Africa”. BMJ Open. https://doi.org/10.1136/bmjopen-2019-029545",
        "Amugsi, Dickson A., Tsala Dimbuene, Zacharie, Asiki, Gershim, Kyobutungi, Catherine. (2018). “Quantile regression analysis of modifiable and non-modifiable drivers’ of blood pressure among urban and rural women in Ghana”. Nature Scientific Reports, 8(8515). Available at https://www.nature.com/articles/s41598-018-26991-4",
        "Zegeye, Elias Asfaw; Mbonigaba, Josue; Tsala Dimbuene, Zacharie. (2018). “Factors associated with the utilization of antenatal care and prevention of mother-to-child HIV transmission services in Ethiopia: Applying a count regression model” BMC Women’s health 18.87. https://doi.org/10.1186/s12905-018-0679-9 ",
        "Izugbara, Chimaraoke O., Kabiru , Caroline W., Amendah, Djesika, Tsala Dimbuene, Zacharie, Donfouet, Hermann Pythagore Pierre, Atake, Esso-Hanam, Ingabire, Marie-Gloriose, Maluka, Stephen, Mumah, Joyce N., Mwau, Matilu , Ndinya, Mollyne, Ngure, Kenneth, Sidze, Estelle M., Sossa, Charles, Soura, Abdramane and Ezeh, Alex C. (2017). “It takes more than a fellowship program”: Reflections on capacity strengthening for health systems research in sub-Saharan Africa” BMC Health Services Research, 1(Suppl 2):696",
        "Tsala Dimbuene, Zacharie, Amo-Adjei, Joshua, Mumah, Joyce, Amugsi, Dickson, Beguy, Donatien, Izugbara, Chimaraoke. (2018). “Women’s education and maternal and health care services utilization in sub-Saharan Africa: A multi-country and socio-economic status analysis”. Journal of Biosocial Science, 50(6), 725-748. https://doi.org/10.1017/S0021932017000505",
        "Pongou, Roland, Kuate Defo, Barthelemy, & Tsala Dimbuene, Zacharie. (2017) “Excess male mortality: Relative contribution of child biology, preconception environment and institutions?” American Economic Review, 107(5), 541-545.",
        "Amugsi DA, Tsala Dimbuene, Z, Mberu M, Muthuri S, and Ezeh, AC. (2017) “Prevalence and time trends in overweight and obesity among urban women: an analysis of demographic and health surveys data from 24 African countries, 1991–2014”. BMJ Open Epidemiology Research Series: 7:e017344. doi:10.1136/bmjopen-2017-017344",
        "Amugsi DA, Tsala Dimbuene, Z, Bakibinga P, et al. (2016). “Dietary Diversity, socioeconomic status and maternal body mass index (BMI): Quantile regression analysis of nationally representative data from Ghana, Namibia, and Sao Tome & Principe”. BMJ Open; 6: e012615. Doi:10.1136/bmlopen-2016-012615",
        "Amugsi DA, Tsala Dimbuene, Z, Kimani-Murage EW, Mberu M, and Ezeh, AC. (2016). “Differential effects of dietary diversity and maternal characteristics on linear growth of children aged 6-59 months in sub-Saharan Africa: a multi-country analysis”. Public Health Nutrition; doi:10.1017/S136890016003426",
        "Dieng C, Mberu B, Tsala Dimbuene Z, Faye C, Amugsi DA, Aboderin I (2016) “Biomedical waste management in Dakar: Legal framework, health and environment issues”. Urban Africa Risk Knowledge, Working Paper #15.",
        "GBD HIV Collaborators (2016). “Global, regional and national incidence, prevalence, and mortality for HIV, 1980-2015: Estimates from the Global Burden Diseases, Injuries, and Risk Factors (GBD) 2015 Study”. Lancet HIV, 3: e361-387.",
        "GBD SDG Collaborators (2016). “Measuring the health-related Sustainable Development Goals in 188 countries: A baseline analysis for the Global Burden Disease Study 2015”. Lancet, 388: 1813- 1850.",
        "GBD (2016). “Global, regional, and national incidence, prevalence, and years lived with disability for 310 diseases and injuries, 1990-2015: a systematic analysis for the Global Burden Disease Study 2015”. Lancet, 388: 1545-1602.",
        "GBD Risk Factors Collaborators (2016). “Global, regional, and national risk assessment of 79 behavioural, environmental, and occupational, and metabolic risks or clusters of risks, 1990-2015: a systematic analysis for the Global Burden Disease Study 2015”. Lancet, 388: 1659-1724.",
        "GBD Maternal Mortality Collaborators (2016). “Global, regional, and national levels of maternal mortality, 1990-2015: a systematic analysis for the Global Burden Disease Study 2015”. Lancet, 388: 1775-1812.",
        "Tsala Dimbuene, Zacharie. (2014). “Families’ response to AIDS: New insights about parental roles in fostering HIV/AIDS knowledge” Journal of Biosocial Science. http://journals.cambridge.org/abstract_S0021932014000406",
        "Murray, Christopher & others, Tsala Dimbuene, Zacharie. (2014). “Global, regional, and national incidence and mortality for HIV, tuberculosis, and malaria during 1990–2013: a systematic analysis for the Global Burden of Disease Study 2013”. Lancet. http://dx.doi.org/10.1016/S0140- 6736(14)60844-8",
        "Tsala Dimbuene, Z., Emina, Jacques B.O., & Osman, Sankoh. (2014.). “UNAIDS ‘multiple sexual partners’ core indicator: An innovative approach to reduce potential biases” Global Health Action. http://dx.doi.org/10.3402/gha.v7.23103",
        "Tsala Dimbuene, Z. & Kuate Defo, Barthelemy. (2013) “Timing of premarital intercourse in Bandjoun (west Cameroon): Does family environment matter?” SageOpen.",
        "Tsala Dimbuene, Z. & Kuate Defo, Barthelemy (2012), “Family environment and premarital intercourse in Bandjoun (west Cameroon)”, Archives of Sexual Behavior, 41(2), pp. 351-361.",
        "Kuate Defo Barthelemy & Tsala Dimbuene, Z. (2012), “Influences of Family Structure Dynamics on Sexual Debut in Africa: Implications for research, practice and policies in reproductive health and social development”. African Journal of Reproductive Health, 16(1), pp. 147-172.",
        "Tsala Dimbuene, Z. & Kuate Defo, Barthelemy (2011), “Fostering accurate HIV/AIDS knowledge among unmarried youths in Cameroon: Do family environment and peers matter?” BMC Public Health. http://www.biomedcentral.com/content/pdf/1471-2458-11-348.pdf",
        "Tsala Dimbuene, Z. & Kuate Defo, Barthelemy (2011), “Risky sexual behaviour among unmarried young people in Cameroon: Another look at family environment”, Journal of Biosocial Science, 43 (2), pp. 129-153.",
        "Godin, J.F., Laplante, B., Ledoux, E., Vultur, M. & Tsala Dimbuene, Z. (2009), Étude exploratoire des parcours d’emploi en lien avec l’apparition des lésions professionnelles chez les jeunes de 16 à 24 ans, Études et Recherches, IRSST, R-630, Montréal (QC), Canada, ISBN : 9782-89631-407-2 (printed version)",

        // ... (other peer-reviewed publications)
      ],
      bookChapter: [
        "Tsala Dimbuene, Zacharie, Haregu, Tilahun, Muanza Nzuzi, Raphaël, & Nzita Kikhela, Paul-Denis (forthcoming). 'The Role of Leadership in sub-Saharan Africa in Promoting Maternal and Child Health'. In Miljana, Z., Jovandaric, Z & Babic, S. (Eds). Maternal and child health."
      ],
      other: [
        "Tsala Dimbuene, Zacharie, and Colleagues (2007). Evaluation Report, Strategic Plan of School Board of Montreal, Quebec, Canada",
        "Tsala Dimbuene, Zacharie, and Colleagues (2008). Evaluation Report, Strategic Plan of School Board of Montreal, Quebec, Canada",
        "Tsala Dimbuene, Zacharie, and Colleagues (2007). Evaluation Report, Strategic Plan of School Board of Montreal, Quebec, Canada available at http://csdm.ca/wp- content/blogs.dir/6/files/Rapport_annuel_evaluation-2006-2007.pdf",
        "Tsala Dimbuene, Zacharie, and Colleagues (2008). Evaluation Report, Strategic Plan of School Board of	Montreal,	Quebec,	Canada available	at http://csdm.ca/wp-content/blogs.dir/6/files/Rapport_annuel_evaluation-2007-2008.pdf",
        "Kuate Defo Barthelemy, Lydié Nathalie, Tsala Dimbuene Zacharie., Tchabewou T, & Camara A. (2007). Enquête sur la Famille et la Santé de la Reproduction au Cameroun 2003. Rapport Final. Université de Montreal: Pronustic Research Laboratory, 320 pages.",        
        "Kuate Defo Barthelemy, Lydié Nathalie, Tchabewou Thierry, Camara Ansoumane, & Tsala Dimbuene Zacharie. (2007). Changements dans les indicateurs de santé de la reproduction au Cameroun à partir des Enquêtes sur la Famille et la Santé de la Reproduction au Cameroun : 1996/97 à 2002. Rapport Final. Université de Montreal: Pronustic Research Laboratory, 465 pages.",        
        "Kuate Defo, Barthelemy, Lydié, Nathalie, Camara, Ansoumane, Tchabewou, Thierry, Tsala Dimbuene, Zacharie & Fossi, Leopold. (2006). Enquête sur la Famille et la Santé de la Reproduction au Cameroun 1996/97. Rapport Final. Université de Montreal: Pronustic Research Laboratory, 430 pages.",
        "Kuate Defo, Barthelemy, Lydié, Nathalie, Tsala Dimbuene, Zacharie, Tchabewou Thierry, & Camara Ansoumane. (2007). Enquête sur la Famille et la Santé de la Reproduction au Cameroun 2002. Rapport Final. Université de Montreal: Pronustic Research Laboratory, 465 pages."
        
              
        // ... (other publications)
      ]
    },
    {
      author:"Bluette Arcady Mongoue",
      leadAuthor:[
        "	Abbasi, Mansoureh; Lebrand, Mathilde Sylvie Maria; Mongoue, Arcady Bluette; Pongou, Roland; Zhang, Fan. 2022. Roads, Electricity, and Jobs: Evidence of Infrastructure Complementarity in Sub-Saharan Africa. Policy Research Working Paper;9976. © WorldBank,Washington,DC. http://hdl.handle.net/10986/37201 License: ⦁	CC BY 3.0 IGO."
      ]
    }
  ];

  const PublicationList = ({ items, maxInitial = 3 }) => {
    const [showAll, setShowAll] = useState(false);
    const displayItems = showAll ? items : items.slice(0, maxInitial);

    return (
      <div>
        <ul className="list-disc pl-5 space-y-2">
          {displayItems.map((item, index) => (
            <li key={index} className="text-sm">{item}</li>
          ))}
        </ul>
        {items.length > maxInitial && (
          <Button
            variant="link"
            onClick={() => setShowAll(!showAll)}
            className="mt-2 text-blue-600 hover:text-blue-800"
          >
            {showAll ? (
              <>
                <ChevronUp className="mr-2 h-4 w-4" />
                Show Less
              </>
            ) : (
              <>
                <ChevronDown className="mr-2 h-4 w-4" />
                Read More
              </>
            )}
          </Button>
        )}
      </div>
    );
  };

  return (
    <div className="bg-gradient-to-b from-blue-50 to-blue-100 min-h-screen">
      <header className="bg-white shadow-md sticky top-0 z-50">
        <div className="container mx-auto px-4 py-6 flex flex-col items-center">
          <img src="/arepalogo1.png" alt="AREPA Logo" className="h-24 md:h-32 mb-6" />
          <nav className="w-full max-w-4xl">
            <ul className="flex flex-wrap justify-center gap-x-6 gap-y-2">
              {navItems.map((item) => (
                <li key={item}>
                  <a
                    href={item === 'Home' ? '#' : `#${item}`}
                    onClick={() => (item === 'Home' ? scrollToTop() : scrollToSection(item))}
                    className={`text-blue-600 hover:text-blue-800 transition-colors font-semibold text-lg ${
                      activeSection === item ? 'underline' : ''
                    }`}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <Button
          variant="outline"
          size="icon"
          className="md:hidden text-blue-600 border-blue-600 hover:bg-blue-100 absolute top-6 right-4"
          onClick={toggleMenu}
        >
          {isMenuOpen ? <X /> : <Menu />}
        </Button>
      </header>

      {isMenuOpen && (
        <div className="fixed inset-0 z-50 bg-white">
          <div className="container mx-auto px-4 py-6">
            <Button
              variant="outline"
              size="icon"
              className="mb-4 text-blue-600 border-blue-600 hover:bg-blue-100 absolute top-6 right-4"
              onClick={toggleMenu}
            >
              <X />
            </Button>
            <ul className="space-y-4 mt-20">
              {navItems.map((item) => (
                <li key={item}>
                  <a
                    href={item === 'Home' ? '#' : `#${item}`}
                    className="block text-xl text-blue-600 hover:text-blue-800 transition-colors font-semibold"
                    onClick={() => {
                      item === 'Home' ? scrollToTop() : scrollToSection(item);
                      toggleMenu();
                    }}
                  >
                    {item}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}

      <main className="container mx-auto px-4 py-8 space-y-12">
        <section id="About">
          <Card className="bg-gradient-to-br from-blue-500 to-blue-600 text-white shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardHeader>
              <CardTitle className="text-3xl">About Us</CardTitle>
            </CardHeader>
            <CardContent className="space-y-8">
            <div>
                <p className="mb-4">
                Alliance for Research on Population - Africa (AREPA) is a non-governmental organization (NGO) registered in Nairobi, Kenya under the NGO Coordination Board.
                </p>
                <p className="mb-4">
                  Our overall objective is improved health and socio-economic status in Africa through the application of population science, statistics, advocacy, and policy analysis. AREPA has superior expertise in applying simple to advanced statistical, analytic, facilitation, and engagement approaches in the health sector.
                </p>
              </div>

              <div className="border-t border-blue-400 pt-6">
                <h3 className="text-2xl font-semibold mb-3">Our Vision</h3>
                <p className="text-lg mb-6">
                  We envision an Africa where data-driven insights empower the well-being and prosperity of every community.
                </p>
              </div>

              <div className="border-t border-blue-400 pt-6">
                <h3 className="text-2xl font-semibold mb-3">Our Mission</h3>
                <p className="text-lg mb-4">
                  We generate and utilize data to drive informed policy-making, effective program development, and sustainable management strategies, all aimed at enhancing the well-being of Africa's diverse populations.
                </p>
                <p className="text-lg">
                  Through our work, we undertake and build capacity in the integrated fields of demographic and health research, policy advocacy, monitoring and evaluation, and proposal development.
                </p>
              </div>

              <div className="border-t border-blue-400 pt-6">
                <h3 className="text-2xl font-semibold mb-4">Core Values</h3>
                <div className="grid md:grid-cols-2 gap-4">
                  <div className="bg-blue-600 rounded-lg p-4">
                    <h4 className="text-xl font-semibold mb-2">Accountability</h4>
                    <p>We uphold the highest standards of accountability, transparency, and integrity in all our processes and actions.</p>
                  </div>
                  <div className="bg-blue-600 rounded-lg p-4">
                    <h4 className="text-xl font-semibold mb-2">Excellence</h4>
                    <p>We are committed to consistently achieving excellence by delivering high-quality results in every aspect of our work.</p>
                  </div>
                  <div className="bg-blue-600 rounded-lg p-4">
                    <h4 className="text-xl font-semibold mb-2">Respect</h4>
                    <p>We treat all individuals and stakeholders with dignity, empathy, and respect, fostering an environment of mutual understanding.</p>
                  </div>
                  <div className="bg-blue-600 rounded-lg p-4">
                    <h4 className="text-xl font-semibold mb-2">Inclusivity</h4>
                    <p>We champion equity, inclusivity, and fairness, ensuring that diverse voices and perspectives are represented and valued in all that we do.</p>
                  </div>
                </div>
              </div>
              <Button
                variant="outline"
                size="icon"
                className="mt-6 rounded-full shadow-lg bg-blue-500 text-white hover:bg-blue-600"
                onClick={scrollToTop}
              >
                <ArrowUp className="h-6 w-6" />
              </Button>
            </CardContent>
          </Card>
        </section>

        <section id="Team">
          <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardHeader>
              <CardTitle className="text-3xl text-blue-600">The Team</CardTitle>
            </CardHeader>
            <CardContent>
              <h3 className="text-2xl font-semibold mb-6 text-blue-800">Board of Directors</h3>
              <ul className="space-y-6">
                {[
                  {
                    name: 'Zacharie Tsala Dimbuene',
                    title: 'Chairman',
                    bio: 'PhD in Demography and Statistics from the Université de Montréal. Senior statistician at Statistics Canada, and Associate Professor at the School of Population and Development Sciences, Faculty of Economics, University of Kinshasa, DRC. Former Associate Research Scientist at the African Population and Health Research Center (APHRC). Expert in policy analysis, sampling designs, data collection, and management in developing countries.',
                    image: '/ZACHARY.png',
                  },
                  {
                    name: 'David I. Ojakaa',
                    title: 'Executive Director',
                    bio: "PhD in Demography and Policy Analysis from the Université de Montréal, Québec, Canada. Over 30 years' experience in sub-Saharan Africa in health research, advocacy, and business development (RABD); health program management; impact evaluations; demographic and health surveys. Experience spans government (public service), and international as well as local NGOs.",
                    image: '/DRDAVID.png',
                  },
                  {
                    name: 'Stella Kasura',
                    title: 'Director',
                    bio:  'Stella Kasura is an administration professional of over 15 years’ experience particularly well-versed in a wide range of complex administrative, operational, and program as well as project support functions within the private, corporate, and international NGO sectors. She is skilled in managing, coordinating, planning and supporting Senior Executives, Principal Investigators (PI), and project teams in the day-to-day office operations, customer care, travel logistics, events, meetings, and conference organization. Stella is good at managing people and initiating communications. Stella holds a Bachelor of Commerce degree in Business Administration and Management from Daystar University Nairobi Campus, in Kenya. Currently, she is  the Administrative Assistant within the Research Programs at  the International Center for Tropical Agriculture (CIAT).  ',
                    image: '/STELLA.png',
                  },
                  {
                    name: 'Bluette Arcady Mongoue',
                    title: 'Director',
                    bio: "Blouette Arcady Mongoue is Lecturer in Economics at the University of Ottawa, upon graduating with a PhD. from the same institution in 2022.  Dr. Mongoue’s avid research interest focuses on development economics, in particular Women empowerment, Demography, Human Capital Formation, Innovation, Employment, Political Economy, Finance, and Credit Access. She is a co-author of notable publications, among them a World Bank policy research series on the nexus between infrastructure and employment.",
                    image: '/BLOUETTE.png',
                  },
                  {
                    name: 'Julius Ojala',
                    title: 'Director',
                    bio: 'Julius Ojala is a graduate computer and electronics engineer with expertise in radio planning, network roll-out, Wimax, microwaves planning, fiber optics, FTTX, CCTV surveillance design, IOT, NBIOT, telematics. base station automation, and Modbus. In addition, Julius has vast experience in IoT water, gas, and electricity metering. Julius is currently an IoT Engineer with Safaricom PLC, an organization he has worked with for the last 15 years. ',
                    image: '/OJALA.png',
                  },
                ].map((member, index) => (
                  <li key={index} className="flex items-center bg-gradient-to-r from-blue-100 to-blue-200 p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <div className="flex-shrink-0">
                      <AvatarComponent src={member.image} alt={member.name} fallback={member.name.charAt(0)} size="w-20 h-20 rounded-full" />
                    </div>
                    <div className="ml-4">
                      <h4 className="text-xl font-semibold text-blue-800">{member.name}</h4>
                      <p className="text-blue-600 font-medium">{member.title}</p>
                      {member.bio && <p className="mt-2 text-gray-700">{member.bio}</p>}
                    </div>
                  </li>
                ))}
              </ul>
              <Button
                variant="outline"
                size="icon"
                className="mt-6 rounded-full shadow-lg bg-blue-500 text-white hover:bg-blue-600"
                onClick={scrollToTop}
              >
                <ArrowUp className="h-6 w-6" />
              </Button>
            </CardContent>
          </Card>
        </section>

        <section id="Focus">
          <Card className="bg-gradient-to-br from-blue-500 to-blue-600 text-white shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardHeader>
              <CardTitle className="text-3xl">Focus</CardTitle>
            </CardHeader>
            <CardContent>
              <p className="mb-4">
                Our priorities are:
              </p>
              <ul className="list-disc list-inside mb-4">
                <li>Analysis of population dynamics for example fertility, mortality, and migration;</li>
                <li>Monitoring and evaluations of health programs and policies;</li>
                <li>Analysis and linkage of health and disease datasets for example community/household health registers as well as medical/disease records such as those for HIV/AIDS, TB, and malaria programs vis a vis population-level censuses and surveys.</li>
              </ul>
              <Button
                variant="outline"
                size="icon"
                className="mt-6 rounded-full shadow-lg bg-blue-500 text-white hover:bg-blue-600"
                onClick={scrollToTop}
              >
                <ArrowUp className="h-6 w-6" />
              </Button>
            </CardContent>
          </Card>
        </section>



        {/* Add similar Card components for other sections with alternating color schemes */}

        <section id="Research">
          <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardHeader>
              <CardTitle className="text-3xl text-blue-600">Research Publications</CardTitle>
            </CardHeader>
            <CardContent>
              <Accordion type="single" collapsible className="w-full">
                {publications.map((pub, index) => (
                  <AccordionItem value={`item-${index}`} key={index}>
                    <AccordionTrigger className="text-xl font-semibold text-blue-800">
                      {pub.author}
                    </AccordionTrigger>
                    <AccordionContent>
                      {pub.leadAuthor && (
                        <div className="mb-4">
                          <h4 className="font-semibold text-blue-700 mb-2">Lead Author Publications</h4>
                          <PublicationList items={pub.leadAuthor} />
                        </div>
                      )}
                      {pub.contributed && (
                        <div className="mb-4">
                          <h4 className="font-semibold text-blue-700 mb-2">Contributed Publications</h4>
                          <PublicationList items={pub.contributed} />
                        </div>
                      )}
                      {pub.peerReviewed && (
                        <div className="mb-4">
                          <h4 className="font-semibold text-blue-700 mb-2">Peer-Reviewed Publications</h4>
                          <PublicationList items={pub.peerReviewed} />
                        </div>
                      )}
                      {pub.bookChapter && (
                        <div className="mb-4">
                          <h4 className="font-semibold text-blue-700 mb-2">Book Chapters</h4>
                          <PublicationList items={pub.bookChapter} />
                        </div>
                      )}
                      {pub.other && (
                        <div className="mb-4">
                          <h4 className="font-semibold text-blue-700 mb-2">Other Publications</h4>
                          <PublicationList items={pub.other} />
                        </div>
                      )}
                    </AccordionContent>
                  </AccordionItem>
                ))}
              </Accordion>
            </CardContent>
          </Card>
        </section>

        <section id="Events">
          <Card className="bg-white shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardHeader>
              <CardTitle className="text-3xl text-blue-600">Events</CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid md:grid-cols-2 gap-8">
                {/* First Event */}
                <div className="space-y-4">
                  <div className="relative overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <img 
                      src="/screenshot.png" 
                      alt=" Paper on use of community household registration data which had been presented for the UAPS conference - March 2024" 
                      className="w-full h-auto object-cover transform hover:scale-105 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                    <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                      <p className="text-lg font-semibold">
                      Paper on use of community household registration data which had been presented for the UAPS conference - March 2024
                      </p>
                    </div>
                  </div>
                  <p className="text-gray-700">
                  Paper on use of community household registration data which had been presented for the UAPS conference
                  </p>
                </div>

                {/* Second Event */}
                <div className="space-y-4">
                  <div className="relative overflow-hidden rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
                    <img 
                      src="/ngocert.png" 
                      alt="AREPA NGO CERTIFICATE ISSUANCE- February 2024" 
                      className="w-full h-auto object-cover transform hover:scale-105 transition-transform duration-300"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent"></div>
                    <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
                      <p className="text-lg font-semibold">
                        AREPA NGO CERTIFICATE ISSUANCE- February 2024
                      </p>
                    </div>
                  </div>
                  <p className="text-gray-700">
                    Dr. David Ojakaa receiving AREPA's NGO certificate from the regulator NGOs Coordination Board .
                  </p>
                </div>
              </div>

              <Button
                variant="outline"
                size="icon"
                className="mt-8 rounded-full shadow-lg bg-blue-500 text-white hover:bg-blue-600"
                onClick={scrollToTop}
              >
                <ArrowUp className="h-6 w-6" />
              </Button>
            </CardContent>
          </Card>
        </section>

        <section id="Contact">
          <Card className="bg-gradient-to-br from-blue-500 to-blue-600 text-white shadow-lg hover:shadow-xl transition-shadow duration-300">
            <CardHeader>
              <CardTitle className="text-3xl">Contact Us</CardTitle>
            </CardHeader>
            <CardContent>
              <p>
                <strong>Website:</strong>{' '}
                <a href="http://www.arepa-africa.org" target="_blank" rel="noopener noreferrer" className="text-yellow-300 hover:underline">
                  www.arepa-africa.org
                </a>
              </p>
              <p>
                <strong>Email:</strong>{' '}
                <a href="mailto:info@brimresearch.org" className="text-yellow-300 hover:underline">
                  info@arepa-africa.org
                </a>
                
              </p>
              <p>
                <strong>Tel:</strong> +254712848967
              </p>
              <p>
                <strong>Address:</strong> Alliance for Research on Population - Africa, P.O. Box 76100-00508, Nairobi, Kenya
              </p>
              <Button
                variant="outline"
                size="icon"
                className="mt-6 rounded-full shadow-lg bg-blue-500 text-white hover:bg-blue-600"
                onClick={scrollToTop}
              >
                <ArrowUp className="h-6 w-6" />
              </Button>
            </CardContent>
          </Card>
        </section>
      </main>

      <Button
        variant="outline"
        size="icon"
        className="fixed bottom-4 right-4 text-blue-600 border-blue-600 hover:bg-blue-100"
        onClick={scrollToTop}
      >
        <ArrowUp className="h-6 w-6" />
      </Button>
    </div>
  );
};

export default App;
